import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Contact.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import contactusbg from '../../Images/contactusbg.png'
import Chimney3 from '../../Images/PhotoImg3.jpg'
import bgnobgs from '../../Images/bgnobgs.png'

export default function Contact() {
    const navigate = useNavigate()

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
                navigate('/thanks')
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="Contact">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Contact Us | Calabasas Air Duct Cleaning | 747-837-2899 | 747-837-2899</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Contact Us | Calabasas Air Duct Cleaning | 747-837-2899" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Contact Us | Calabasas Air Duct Cleaning | 747-837-2899" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.calabasasairduct.org/contact" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Get in touch with Calabasas Air Duct Cleaning | 747-837-2899 for all your Air Duct service needs. Contact us today for expert advice, scheduling, and answers to your questions. We're here to help!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Get in touch with Calabasas Air Duct Cleaning | 747-837-2899 for all your Air Duct service needs. Contact us today for expert advice, scheduling, and answers to your questions. We're here to help!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Contact Us | Calabasas Air Duct Cleaning | 747-837-2899" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>CONTACT US</h1>
                    <h2>Monday - Sunday 8 a.m. - 8:00 p.m. ET</h2>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Calabasas Air Duct Cleaning  contact us" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Here is how we can help you today.</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <div className="MainBanner2TopBoxBox">
                            <h3>Help with a new service.</h3>
                            <a target='_blank' href="mailto:info@Calabasashimneysweep.org">info@Calabasashimneysweep.org</a>
                            <a target='_blank' href="tel:747-837-2899">747-837-2899</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Mailing address.</h3>
                            <a>Calabasas California</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Partner with us.</h3>
                            <a target='_blank' href="mailto:admin@Calabasasairduct.com">admin@Calabasasairduct.com</a>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="ContactBanner2">
                <h2>Is there anything else you'd like to know?</h2>
                <a href='/faq'>Feel free to explore our Frequently Asked Questions. <u> (FAQ) page.</u> </a>
            </div>
            <div className="ContactFirst">

                <div className="ContactFirstBody">
                    <form className="contact-form" onSubmit={sendEmail}>
                        <h4>Don't hesitate to reach out via email!</h4>
                        <h5>If you need help applying for a new service or have questions about an existing one, please send us an email. Our loan advisory team is here to assist you!</h5>
                        <h2>Full Name</h2>
                        <input type="text" name="Full_Name" required />
                        <h2>Email</h2>
                        <input type="email" name="Email" required />
                        <div className='ContactFirsthhh'>
                            <div className="ContactFirsthhhBox">
                                <h2>Phone</h2>
                                <input type="tel" name="Phone" required />
                            </div>
                            <div className="ContactFirsthhhBox">
                                <h2>State</h2>
                                <input type="text" name="Country" required />
                            </div>
                        </div>
                        <h2>Massage</h2>
                        <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                        <input type="submit" value="SEND" />
                        <input type="hidden" name="Company_From" defaultValue={`Calabasas, CA Air Duct Cleaning | ROTO`} />
                        <input type="hidden" name="Company" defaultValue={`Calabasas, CA Air Duct Cleaning | ROTO`} />

                    </form>
                </div>
            </div>
            <NavigatorPath />

        </div>
    )
}

